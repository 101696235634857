import { Box } from "@mui/material";
import { useTenantTheme } from "../../../themes/TenantThemeProvider";

export function ProgressBar(props) {
  const theme = useTenantTheme();

  return (
    <Box
      height="6px"
      display="flex"
      bgcolor="#F2F2F2"
      borderRadius="3px"
      mb="32px"
    >
      <Box
        height="6px"
        bgcolor={theme.siTheme.primaryColor}
        width={props.width}
        borderRadius="3px"
        style={{
          transition: `width .5s ease-in-out`,
        }}
      />
    </Box>
  );
}
