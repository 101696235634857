import { api } from "./index";

export function getOrderLookup({ shipInsureId, orderNumber, email }) {
  return api.get("/webapp/order_lookup", {
    params: {
      ship_insure_id: shipInsureId,
      order_number: orderNumber,
      email_address: email,
    },
  });
}
