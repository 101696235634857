export const baseTheme = {
  colors: {
    primary: "#6675FF",
    secondary: "#F4F4F9",
    text: "#06001E",
    textLight: "#3D5781",
    neutral: "#FFFFFF",
    error: "#FF3E33",
    success: "#81c784",
  },
  fonts: {
    main: "Open Sans, sans-serif",
    secondary: "Montserrat, sans-serif",
  },
};
