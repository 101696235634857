import { Box, Button, Typography } from "@mui/material";
import { useClaims } from "../ClaimsProvider";
import { LoadingButton } from "@mui/lab";

export function ReviewClaim() {
  const {
    claimsForm,
    order,
    setActiveStep,
    onClaimSubmit,
    isSubmitting,
    setIsSubmitting,
  } = useClaims();
  // any form item that has a reason is claimable
  const claimableItems = claimsForm
    .getValues()
    .items.filter((d) => d.reason.length > 1);
  const items = claimableItems.map((claimableItem) => ({
    ...claimableItem,
    lineItem: order.line_items.find((d) => d.id === claimableItem.itemId),
  }));

  return (
    <>
      <Typography variant="h3">Let’s confirm we got all that.</Typography>
      <Typography paragraph>
        Please make sure everything looks right, then submit. Our support team
        will take it from there!{" "}
      </Typography>
      <Typography fontWeight="bold" fontSize="20px">
        Items
      </Typography>
      <Box>
        {items.map((item, index) => (
          <Box
            key={index}
            py="30px"
            borderBottom="1px solid #E0E3FF"
            sx={{
              "&:last-child": {
                border: 0,
              },
            }}
          >
            <Typography>{item.lineItem.title}</Typography>
            <Typography fontSize="13px" color="#423E56">
              ${item.lineItem.price}
            </Typography>
            <Typography mt="10px">
              <Typography component="span" fontWeight="bold">
                Reason:
              </Typography>{" "}
              {item.reason}
            </Typography>
            <Typography mt="10px">
              <Typography component="span" fontWeight="bold">
                Description:
              </Typography>{" "}
              {item.details}
            </Typography>
            <Typography mt="10px">
              <Typography component="span" fontWeight="bold">
                Requested Resolution:
              </Typography>{" "}
              {item.resolution}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" mt="20px">
        <Box flex="0 0 10%" mr="20px">
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              setIsSubmitting(false);
              setActiveStep(1);
            }}
          >
            Back
          </Button>
        </Box>
        <Box flex="1">
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            fullWidth
            onClick={() => {
              setIsSubmitting(false);
              onClaimSubmit(items);
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}
