import { api } from './index';

export function getMerchant(id) {
    return api.get(`/merchants/${id}`);
}

export function createMerchant(data) {
    return api.post('/merchants', data);
}

export function updateMerchant(id, data) {
    return api.put(`/merchants/${id}`, data);
}
