import { Component } from "react";
import { Notifier } from "@airbrake/browser";
import { Typography, Container, Box } from "@mui/material";
import { Header } from "../../shared/Header";
import { ERROR, track } from "../../helpers/heap";

const projectId = import.meta.env.VITE_AIRBRAKE_PROJECT_ID;
const projectKey = import.meta.env.VITE_AIRBRAKE_PROJECT_KEY;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = new Notifier({
      projectId,
      projectKey,
      environment: process.env.NODE_ENV,
    });

    this.airbrake.addFilter((notice) => {
      if (process.env.NODE_ENV === "development") {
        // Ignore errors from development
        return null;
      }

      return notice;
    });
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    track(ERROR, {
      component: "ErrorBoundary",
      message: error.message,
    });

    if (process.env.NODE_ENV !== "development") {
      // Send error to Airbrake
      this.airbrake.notify({
        error: error,
        params: { info },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Header />
          <Box mt="32px" textAlign="center">
            <Typography variant="h2">
              We're sorry, something went wrong
            </Typography>
            <Typography paragraph>
              Please try to refresh the page and try again.
            </Typography>
          </Box>
        </Container>
      );
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
