import React from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";

export function ThankYou() {
  const [searchParams] = useSearchParams();
  const shipInsureId = searchParams.get("shipInsureId");

  return (
    <>
      <Typography variant="h3">Thank you for submitting your claim!</Typography>
      <Typography paragraph>
        Your claim has been received and is being reviewed by one of our team.
        You can expect a response within 24 hours.
      </Typography>
      <Button
        sx={{ marginTop: "20px" }}
        component={RouterLink}
        to={`/claims/${shipInsureId}`}
        variant="contained"
        fullWidth
      >
        View your claim
      </Button>
    </>
  );
}
