import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { GradientBox } from "./shared/GradientBox";
import { useOnboarding, steps } from "./OnboardingProvider";
import { baseTheme } from "../../themes/shipinsure";

export function Welcome() {
  const { merchant } = useOnboarding();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <GradientBox>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ padding: { xs: "40px", md: "150px" } }}
      >
        <Typography
          variant="h1"
          color="#fff"
          fontWeight={300}
          fontFamily="Montserrat, sans-serif"
          mb="47px"
          sx={{
            fontSize: { xs: "66px", md: "86px" },
            lineHeight: { xs: "initial", md: "85px" },
          }}
        >
          Welcome
          {merchant?.id && merchant?.full_name ? `, ${merchant.full_name}` : ""}
        </Typography>
        <Typography
          variant="h2"
          color="#fff"
          fontWeight={600}
          fontFamily="Montserrat, sans-serif"
          mb="75px"
          sx={{
            fontSize: { xs: "25px", md: "45px" },
            lineHeight: { xs: "initial", md: "52px" },
          }}
        >
          We have just a few questions for you to answer
        </Typography>
        <Box width="324px">
          <ThemeProvider theme={buttonTheme}>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => navigate(`${steps.STEP_1}${location.search}`)}
            >
              Lets Get Started
            </Button>
          </ThemeProvider>
        </Box>
      </Box>
    </GradientBox>
  );
}

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      light: "#fff",
      dark: "#fff",
      contrastText: baseTheme.colors.primary,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: "Open Sans, sans-serif",
          boxShadow: "none",
          borderRadius: "100px",
          textTransform: "none",
          fontWeight: 600,
          "&.MuiButton-sizeMedium": {
            height: "56px",
            fontSize: "16px",
          },
        },
      },
    },
  },
});
