export function CreditCard() {
  return (
    <svg
      width="48"
      height="38"
      viewBox="0 0 48 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.3083 17.5369H0.674416C0.297536 17.5369 0 17.8344 0 18.2113V32.255C0 35.1907 2.38029 37.5908 5.33582 37.5908H42.6469C45.5826 37.5908 47.9827 35.2105 47.9827 32.255V18.2113C47.9827 17.8344 47.6852 17.5369 47.3083 17.5369Z"
        fill="white"
      />
      <path
        d="M42.6469 0.934082H5.33582C2.38029 0.934082 0 3.31437 0 6.2699V13.5496C0 13.9265 0.297536 14.224 0.674416 14.224H47.3083C47.6852 14.224 47.9827 13.9265 47.9827 13.5496V6.2699C47.9827 3.33421 45.6024 0.934082 42.6469 0.934082ZM7.99381 10.2569C6.52597 10.2569 5.33582 9.06674 5.33582 7.5989C5.33582 6.13105 6.52597 4.94091 7.99381 4.94091C9.46166 4.94091 10.6518 6.13105 10.6518 7.5989C10.6518 9.06674 9.46166 10.2569 7.99381 10.2569ZM16.662 10.2569C15.1942 10.2569 14.004 9.06674 14.004 7.5989C14.004 6.13105 15.1942 4.94091 16.662 4.94091C18.1299 4.94091 19.32 6.13105 19.32 7.5989C19.32 9.06674 18.1299 10.2569 16.662 10.2569Z"
        fill="white"
      />
    </svg>
  );
}

export function Bank() {
  return (
    <svg
      width="54"
      height="57"
      viewBox="0 0 54 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.4827 45.4421V47.5844H21.1961V45.4619C21.335 45.4223 21.4936 45.3826 21.6523 45.3231C22.4458 45.0454 22.9218 44.4702 23.0408 43.6371C23.0607 43.4387 23.0607 43.2602 23.0607 43.0618C23.0607 37.1706 23.0607 31.2794 23.0607 25.3882C23.0607 23.9005 22.6441 23.3252 21.1763 22.869V20.7466H32.5223C32.5223 21.4012 32.5223 22.0359 32.5223 22.6707C32.5223 22.7698 32.3438 22.9087 32.2248 22.9484C31.0743 23.3649 30.6578 23.96 30.6578 25.1898C30.6578 31.1207 30.6578 37.0317 30.6578 42.9626C30.6578 44.4305 31.0941 45.0256 32.5025 45.4421H32.4827Z"
        fill="white"
      />
      <path
        d="M13.5415 20.7466V22.869C13.4225 22.9087 13.2836 22.9484 13.1646 22.988C12.2323 23.2856 11.6968 23.9996 11.6968 24.9914C11.6968 31.1009 11.6968 37.2301 11.6968 43.3395C11.6968 44.4107 12.272 45.0454 13.5812 45.4619V47.5645H1.93756V45.4619C2.15575 45.4223 2.33428 45.4024 2.5128 45.3628C3.48475 45.1644 4.09966 44.3313 4.09966 43.2403C4.09966 41.1973 4.09966 39.1542 4.09966 37.1111C4.09966 33.2431 4.09966 29.3751 4.09966 25.527C4.09966 23.7815 3.6236 23.1864 1.91772 22.869V20.7664H13.5216L13.5415 20.7466Z"
        fill="white"
      />
      <path
        d="M40.1404 22.869V20.7466H51.4269V22.869C51.4269 22.869 51.2881 22.9285 51.2087 22.9484C50.1178 23.2657 49.5822 23.96 49.5822 25.0906C49.5822 28.2842 49.5822 31.4976 49.5822 34.6911C49.5822 37.4285 49.5822 40.146 49.5822 42.8833C49.5822 44.4305 49.9789 44.9859 51.4468 45.4619V47.5645H40.1602V45.4421C40.2594 45.4223 40.3784 45.3826 40.4974 45.3429C41.4495 45.0454 42.0248 44.3511 42.0248 43.3395C42.0248 37.2103 42.0248 31.1009 42.0248 24.9716C42.0248 23.8806 41.4495 23.2459 40.1404 22.869Z"
        fill="white"
      />
      <path
        d="M1.0018 15.072L25.737 1.18699C26.3321 0.84978 27.0462 0.84978 27.6412 1.18699L52.0392 15.072C53.7649 16.044 53.0707 18.6821 51.0871 18.6821H1.93408C-0.0494994 18.6821 -0.743751 16.044 0.98196 15.072H1.0018Z"
        fill="white"
      />
      <path
        d="M49.5218 49.646H3.48298C1.56585 49.646 0.0117188 51.2001 0.0117188 53.1173V53.1371C0.0117188 55.0542 1.56585 56.6083 3.48298 56.6083H49.5218C51.4389 56.6083 52.993 55.0542 52.993 53.1371V53.1173C52.993 51.2001 51.4389 49.646 49.5218 49.646Z"
        fill="white"
      />
    </svg>
  );
}
export function ShipInsure() {
  return (
    <svg
      width="59"
      height="57"
      viewBox="0 0 59 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.6945 12.3825L26.628 1.05575C26.2867 0.893853 25.8929 0.893853 25.5487 1.05575L1.66887 12.2942C1.21968 12.5061 0.933838 12.9594 0.933838 13.4598V44.3611C0.933838 44.6113 1.07676 44.8379 1.30136 44.9439L25.5487 56.3531C25.89 56.5149 26.2838 56.5149 26.628 56.3531L50.8753 44.9439C51.0999 44.8379 51.2429 44.6113 51.2429 44.3611V26.17L57.7357 23.1146C58.0973 22.9439 58.2169 22.4847 57.9836 22.158L51.5958 13.139C51.3654 12.8122 51.0533 12.5532 50.6945 12.3825ZM26.628 2.74534L46.4097 12.0557C46.8997 12.2853 46.8997 12.9918 46.4097 13.2214L26.628 22.5318C26.2867 22.6937 25.8929 22.6937 25.5487 22.5318L5.76698 13.2214C5.27696 12.9918 5.27696 12.2853 5.76698 12.0557L25.5487 2.74534C25.89 2.58344 26.2838 2.58344 26.628 2.74534ZM36.863 39.1952C36.4138 39.4071 36.128 39.8604 36.128 40.3608V50.1893L28.7922 53.6421C28.3693 53.8422 27.8851 53.5302 27.8851 53.0592V27.0119L32.9662 34.1853C33.1441 34.4384 33.4766 34.5238 33.7537 34.3943L49.4432 27.0119V43.5133C49.4432 43.7635 49.3003 43.9902 49.0757 44.0962L43.3121 46.8101V37.17C43.3121 36.699 42.8279 36.387 42.4049 36.5872"
        fill="white"
      />
    </svg>
  );
}

export function Plaid(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={126}
      height={48}
      {...props}
    >
      <defs>
        <path id="a" d="M0 47.473h126V0H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#111"
          d="M66.248 16.268c-1.057-.889-2.861-1.333-5.413-1.333h-5.756v17.788h4.304v-5.575h1.928c2.34 0 4.056-.515 5.148-1.546 1.23-1.155 1.849-2.693 1.849-4.613 0-1.991-.687-3.565-2.06-4.721m-5.044 6.855h-1.821V18.96h1.636c1.99 0 2.985.698 2.985 2.094 0 1.378-.934 2.068-2.8 2.068m14.469-8.188h-4.488v17.788h9.69v-4.026h-5.202zm13.995 0-7.05 17.788h4.832l.924-2.586H94.5l.845 2.586h4.886l-7-17.788h-3.563zm-.053 11.601 1.849-6.08 1.82 6.08h-3.67z"
        />
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#111"
          d="M102.473 32.722h4.489V14.934h-4.489zm21.917-14.454a7.376 7.376 0 0 0-2.14-2.053c-1.355-.854-3.204-1.28-5.545-1.28h-5.914v17.787h6.918c2.5 0 4.506-.817 6.02-2.453 1.514-1.635 2.27-3.805 2.27-6.508 0-2.15-.537-3.981-1.61-5.493m-7.182 10.427h-1.927v-9.734h1.954c1.373 0 2.428.43 3.168 1.287.74.857 1.11 2.073 1.11 3.647 0 3.2-1.435 4.8-4.305 4.8M18.637 0 4.09 3.81.081 18.439l5.014 5.148L0 28.65l3.773 14.693 14.484 4.047 5.096-5.064 5.014 5.147 14.547-3.81 4.008-14.63-5.013-5.146 5.095-5.063L43.231 4.13 28.745.083l-5.094 5.063L18.637 0zM9.71 6.624l7.663-2.008 3.351 3.44-4.887 4.856L9.71 6.624zm16.822 1.478 3.405-3.383 7.63 2.132-6.227 6.187-4.808-4.936zm-21.86 9.136 2.111-7.705 6.125 6.288-4.886 4.856-3.35-3.44zm29.547-1.243 6.227-6.189 1.986 7.74-3.404 3.384-4.809-4.935zm-15.502-.127 4.887-4.856 4.807 4.936-4.886 4.856-4.808-4.936zm-7.814 7.765 4.886-4.856 4.81 4.936-4.888 4.856-4.808-4.936zm15.503.127 4.886-4.856L36.1 23.84l-4.887 4.856-4.807-4.936zM4.57 29.927l3.406-3.385 4.807 4.937-6.225 6.186-1.988-7.738zm14.021 1.598 4.887-4.856 4.808 4.936-4.886 4.856-4.809-4.936zm15.502.128 4.887-4.856 3.351 3.439-2.11 7.705-6.128-6.288zm-24.656 8.97 6.226-6.189 4.81 4.936-3.406 3.385-7.63-2.133zm16.843-1.206 4.886-4.856 6.126 6.289-7.662 2.007-3.35-3.44z"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
}

export function Stripe(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: "new 0 0 468 222.5",
      }}
      viewBox="0 0 468 222.5"
      {...props}
    >
      <path
        d="M414 113.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5v-20c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16h-25.8zm-63.5-36.3c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4zm-71.3-74.8 25.1-5.4V36l-25.1 5.3zm0 7.6h25.1v87.5h-25.1zm-26.9 7.4-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4zm-50-29.1-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V135c-3.2 1.3-19 5.9-19-8.9V90.6h19V69.3h-19l.1-21.7zM79.3 94.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6C67.5 67.6 54 78.2 54 95.9c0 27.6 38 23.2 38 35.1 0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          fill: "#000000",
        }}
      />
    </svg>
  );
}
