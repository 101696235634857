import { useState } from "react";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

export function PaymentForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    try {
      setIsLoading(true);

      await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.href}?payment=success`,
        },
      });
    } catch (error) {
      props.onError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PaymentElement />
      <LoadingButton
        loading={isLoading}
        sx={{ width: "100%", mb: "8px", mt: "24px" }}
        variant="contained"
        disabled={!stripe}
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
      <Button onClick={props.onCancel} sx={{ width: "100%" }} variant="link">
        Cancel
      </Button>
    </div>
  );
}
