import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { makeStyles } from "@mui/styles";

export function Menu(props) {
  const steps = ["Account", "Store Info", "Coverage", "Billing"];
  const styles = useStyles();

  return (
    <Box padding="41px 41px 41px 31px">
      <Typography
        fontFamily="Montserrat, sans-serif"
        variant="h4"
        fontSize="28px"
        color="#fff"
        fontWeight={700}
        mb="30px"
      >
        Onboarding
      </Typography>
      <ul className={styles.stepsList}>
        {steps.map((step, i) => (
          <li className={props.step <= i ? styles.inactive : ""} key={i}>
            {props.step <= i ? (
              <RadioButtonUncheckedIcon />
            ) : (
              <CheckCircleIcon />
            )}{" "}
            {step}
          </li>
        ))}
      </ul>
    </Box>
  );
}

const useStyles = makeStyles({
  stepsList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    color: "#fff",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: ".5px",
    "& li": {
      padding: "16px 24px",
      display: "flex",
      justfyContent: "center",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "16px",
    },
  },
  inactive: {
    opacity: 0.6,
  },
});
