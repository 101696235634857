import { Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { Loader } from "./shared/Loader";
import {
  Welcome,
  Account,
  Information,
  Coverage,
  Billing,
  OnboardingProvider,
} from "./pages/onboarding";
import { Claims, Status } from "./pages/claims";
import { Tracking } from "./pages/tracking";
import { Home } from "./pages/home";
import { ClaimsProvider } from "./pages/claims/ClaimsProvider";
import { useTenantTheme } from "./themes/TenantThemeProvider";

function App() {
  const { isThemeLoading } = useTenantTheme();
  if (isThemeLoading) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <div className="App">
        <div className="Pages">
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="claims">
                <Route
                  index
                  element={
                    <ClaimsProvider>
                      <Claims />
                    </ClaimsProvider>
                  }
                />
                <Route path=":claimNumber" element={<Status />} />
              </Route>
              <Route path="tracking">
                <Route path=":shipInsureId" element={<Tracking />} />
              </Route>
              <Route
                path="onboarding"
                element={
                  <OnboardingProvider>
                    <Outlet />
                  </OnboardingProvider>
                }
              >
                <Route index element={<Welcome />} />
                <Route path="account" element={<Account />} />
                <Route path="information" element={<Information />} />
                <Route path="coverage" element={<Coverage />} />
                <Route path="billing" element={<Billing />} />
              </Route>
            </Route>
          </Routes>
        </div>
      </div>
    </Suspense>
  );
}

export default App;
