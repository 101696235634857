import { api } from './index';

export function createPlaidToken() {
    return api.post('/payments/plaid/token');
}

export function createPlaidPayment(data) {
    return api.post('/payments/plaid', data);
}

export function createStripeSetup(data) {
    return api.post('/payments/stripe/setup', data);
}
