import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { MainLayout } from "../../layouts/MainLayout";
import { Card } from "../../shared/Card";
import { useTenantTheme } from "../../themes/TenantThemeProvider";

export function Home() {
  const { whitelabel } = useTenantTheme();
  const storeName = whitelabel?.custom_attributes?.store_name ?? "ShipInsure";

  return (
    <MainLayout>
      <Card>
        <Typography variant="h3">{storeName} is here to help</Typography>
        <Typography paragraph>
          We're here to assist you every step of the way. Click below to file a
          claim or visit ShipInsure.io to learn more about our protection
          against lost, damaged, or stolen packages.
        </Typography>
        <Box my="30px">
          <Button variant="contained" fullWidth to="/claims" component={Link}>
            File a Claim
          </Button>
        </Box>
        <Button
          variant="outlined"
          fullWidth
          href="https://shipinsure.io"
          target="_blank"
        >
          Visit Shipinsure.io
        </Button>
      </Card>
    </MainLayout>
  );
}
