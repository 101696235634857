import { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Box, CircularProgress } from "@mui/material";
import { createStripeSetup } from "../../api/payments";
import { PaymentForm } from "./PaymentForm";

const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export function StripeProvider({ merchantId, onCancel, onError }) {
  const [clientSecret, setClientSecret] = useState();

  useEffect(() => {
    if (!merchantId) return;

    createStripeSetup({ merchant_id: merchantId }).then((response) =>
      setClientSecret(response.data.client_secret)
    );
  }, [merchantId]);

  if (!clientSecret) return <CircularProgress />;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentForm onCancel={onCancel} onError={onError} />
    </Elements>
  );
}
