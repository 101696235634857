import { baseTheme } from "../themes/shipinsure";

export const muiTheme = {
  palette: {
    primary: {
      main: baseTheme.colors.primary,
    },
    error: {
      main: baseTheme.colors.error,
    },
    success: {
      main: baseTheme.colors.success,
    },
  },
  typography: {
    fontFamily: baseTheme.fonts.main,
    allVariants: {
      color: baseTheme.colors.text,
    },
    h1: {
      fontFamily: baseTheme.fonts.secondary,
      fontSize: "56px",
      lineHeight: "56px",
      fontWeight: 300,
      marginBottom: "28px",
    },
    h2: {
      fontFamily: baseTheme.fonts.secondary,
      fontSize: "34px",
      lineHeight: "36px",
      fontWeight: 700,
      marginBottom: "28px",
    },
    h3: {
      fontFamily: baseTheme.fonts.secondary,
      fontSize: "28px",
      lineHeight: "32px",
      fontWeight: 700,
      marginBottom: "28px",
    },
    h4: {
      fontFamily: baseTheme.fonts.secondary,
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: 700,
      marginBottom: "28px",
    },
    h5: {
      fontFamily: baseTheme.fonts.secondary,
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 700,
      marginBottom: "28px",
    },
    h6: {
      fontFamily: baseTheme.fonts.secondary,
      fontSize: "17px",
      lineHeight: "20px",
      fontWeight: 700,
      marginBottom: "28px",
    },
    p: {
      fontSize: "16px",
      fontWeight: 400,
      marginBottom: "12px",
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "11px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#F4F4F9",
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMenu: {
      defaultProps: {
        disableScrollLock: true,
      },
      styleOverrides: {
        list: {
          background: baseTheme.colors.secondary,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "100px",
          textTransform: "none",
          fontWeight: 600,
          "&.MuiButton-sizeMedium": {
            height: "40px",
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderTopWidth: "4px",
          borderColor: "#D7D6DB",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": { color: "rgba(6, 0, 30, 0.75)" },
          "&.Mui-active": { color: "#D7D6DB" },
          color: "#D7D6DB",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          "&.Mui-completed, &.Mui-active": {
            fontSize: "11px",
            fontWeight: 600,
          },
        },
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          fontFamily: baseTheme.fonts.main,
          margin: 0,
          backgroundColor: "#fff",
          maxWidth: "100%",
          overflowX: "hidden",
        },
        html: {
          scrollBehavior: "smooth",
        },
        video: {
          width: "100%",
          height: "100%",
        },
        ".video-react": {
          height: "500px !important",
          paddingTop: "unset !important",
          width: "500px !important",
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: baseTheme.colors.neutral,
        backgroundColor: baseTheme.colors.primary,
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "unset",
        },
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
      },
    },
    MuiInput: {
      formControl: {
        "label + &": {
          marginTop: 0,
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        left: "unset",
        transform: "translate(0, 16px) scale(1)",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: ".9rem",
        minHeight: "40px",
      },
    },
    MuiAccordion: {
      root: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
};
