import { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";
import { baseTheme } from "../themes/shipinsure";

// https://cloudinary.com/blog/guest_post/upload-images-with-react-dropzone

export default function ImageDropUpload(props) {
  const [selectedImages, setSelectedImages] = useState(props.images);
  const { onImageChange } = props;

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedImages((prevState) => [...prevState, ...acceptedFiles]);
  }, []);

  const handleRemoveImage = (index) => {
    setSelectedImages(selectedImages.filter((_, i) => i !== index));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpg, image/jpeg, image/png",
  });

  useEffect(() => {
    if (onImageChange) {
      onImageChange(selectedImages);
    }
  }, [selectedImages]);

  return (
    <Box>
      {selectedImages.length > 0 && (
        <Box
          sx={{
            mb: "15px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(90px, 1fr))",
            width: "100%",
            gridGap: "1rem",
          }}
        >
          {selectedImages.map((image, index) => (
            <Box
              key={index}
              border="1px solid #D3D3D3"
              borderRadius="8px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
              sx={{
                "& img": {
                  height: "90px",
                  width: "100%",
                  overflow: "hidden",
                  objectFit: "cover",
                  borderRadius: "8px",
                },
              }}
            >
              <IconButton
                onClick={() => handleRemoveImage(index)}
                size="small"
                sx={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  background: baseTheme.colors.neutral,
                  boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
                  width: "30px",
                  height: "30px",
                  "& svg": {
                    width: "20px",
                    color: baseTheme.colors.text,
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <img src={`${URL.createObjectURL(image)}`} key={index} alt="" />
            </Box>
          ))}
        </Box>
      )}
      <Box
        {...getRootProps()}
        padding="16px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        border="1px dashed #807D8C"
        borderRadius="16px"
        width="100%"
        textAlign="center"
        height="150px"
        sx={{ cursor: "pointer" }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>Drop file(s) here</Typography>
        ) : (
          <Box>
            <FileUploadOutlinedIcon fontSize="large" color="#343330" />
            <Typography>
              Drag & Drop or{" "}
              <Typography component="span" color={baseTheme.colors.primary}>
                Choose file
              </Typography>{" "}
              to upload
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
