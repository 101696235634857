import { OrderLookup } from "./steps/OrderLookup";
import { ResolveClaim } from "./steps/ResolveClaim";
import { ThankYou } from "./steps/ThankYou";
import { useClaims } from "./ClaimsProvider";
import { ReviewClaim } from "./steps/ReviewClaim";
import { ResolveAllClaim } from "./steps/ResolveAllClaim";

export function Claims() {
  const { activeStep, isSelectAll } = useClaims();

  const renderStep = (activeStep) => {
    switch (activeStep) {
      case 1: {
        return isSelectAll ? <ResolveAllClaim /> : <ResolveClaim />;
      }
      case 2: {
        return <ReviewClaim />;
      }
      case 3: {
        return <ThankYou />;
      }
      case 0:
      default: {
        return <OrderLookup />;
      }
    }
  };

  return renderStep(activeStep);
}
