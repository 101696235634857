import { Box } from "@mui/material";
import { base64Noise } from "./base64Noise";

export function GradientBox(props) {
  return (
    <Box
      boxShadow="0px 32px 64px -12px rgba(0, 0, 0, 0.14)"
      borderRadius="32px"
      sx={{
        backgroundImage: `url(data:image/png;base64,${base64Noise}), radial-gradient(113% 91% at 17% -2%, #4C5EBE 1%, #FF000000 99%),radial-gradient(143% 95% at 83% 7%, #ACDBFF 0%, #FF000000 99%),radial-gradient(200% 91% at -50% 0%, #4C5EBE 1%, #C4353500 99%),radial-gradient(142% 91% at 0% 84%, #C74FC2 0%, #ACDBFF 100%)`,
        height: { xs: "auto", md: "720px" },
      }}
    >
      {props.children}
    </Box>
  );
}
