import { Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ERROR, identify, track } from "../../../helpers/heap";
import { getOrderLookup } from "../../../api/orderLookup";
import { useClaims } from "../ClaimsProvider";

export function OrderLookup() {
  const { onOrderLookup, onOrderLookupError } = useClaims();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: searchParams.get("email") ?? "",
      orderNumber:
        (searchParams.get("order_number") ||
          searchParams.get("ship_insure_id")) ??
        "",
    },
  });

  const handleFormSubmit = async (values) => {
    const { orderNumber, email } = values;
    const isShipInsureId = orderNumber.startsWith("SI");

    try {
      const response = await getOrderLookup(
        isShipInsureId ? { shipInsureId: orderNumber } : { orderNumber, email }
      );

      // identify order in Heap on order lookup
      if (response.data?.shipInsureID) {
        identify(response.data.shipInsureID);
      }

      if (response.data.has_claims) {
        navigate(`/claims/${response.data.shipInsureID}`);
      } else {
        onOrderLookup(response.data);
      }
    } catch (error) {
      let message = error.message;

      if (error.response?.status === 404) {
        message = error.response.data.Message;
      }

      track(ERROR, {
        message,
      });

      onOrderLookupError(message);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Typography variant="h3">Claim Resolution</Typography>
      <Typography paragraph mb="0px">
        We’re sorry to hear that you’re having an issue with your order. File
        your claim here in less than a minute.
      </Typography>
      <Grid container spacing="46px" mt="0px">
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={errors.email ? true : false}
                helperText={errors.email?.message}
                label="Email Address"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="orderNumber"
            control={control}
            rules={{
              required: "Required",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                error={errors.orderNumber ? true : false}
                helperText={errors.orderNumber?.message}
                label="Order Number"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
      <LoadingButton
        loading={isSubmitting}
        variant="contained"
        fullWidth
        type="submit"
        sx={{ mt: "46px" }}
      >
        Next
      </LoadingButton>
    </form>
  );
}
