import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import { baseTheme } from "../../themes/shipinsure";
import { GradientBox } from "./shared/GradientBox";
import { Menu } from "./shared/Menu";
import { useOnboarding } from "./OnboardingProvider";

export function Account() {
  const [pocEnabled, setPocEnabled] = useState(false);
  const { merchant, form } = useOnboarding();

  const {
    control,
    getValues,
    formState: { errors, isSubmitting, isValid },
    setValue,
    watch,
  } = form;

  const passwordField = watch("password");
  const { pointOfContact, pointOfContactEmail } = getValues();

  useEffect(() => {
    if (!merchant) return;

    if (merchant && !isValid) {
      setValue("fullName", merchant.full_name);
      setValue("email", merchant.email_address);
      setValue("phoneNumber", merchant.phone);
    }
  }, [merchant]);

  return (
    <GradientBox>
      <Box display="flex" padding="14px" height="100%">
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Menu step={1} />
        </Box>
        <Box
          bgcolor="#fff"
          width="100%"
          borderRadius="24px"
          display="flex"
          flexDirection="column"
          sx={{ padding: { xs: "30px", md: "40px 69px" } }}
        >
          <Typography variant="h3" mb="32px" color={baseTheme.colors.textLight}>
            Welcome
            {merchant?.id && merchant?.full_name
              ? `, ${merchant.full_name}`
              : ""}
            !
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="32px"
          >
            <Typography
              paragraph
              mb={0}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              Let's start with the basics:
            </Typography>
            <ThemeProvider theme={buttonTheme}>
              <Button variant="contained" onClick={() => setPocEnabled(true)}>
                <AddIcon
                  sx={{ color: baseTheme.colors.primary, marginRight: "8px" }}
                />{" "}
                Add different point of contact
              </Button>
            </ThemeProvider>
          </Box>
          <Grid
            container
            columnSpacing="60px"
            rowSpacing="32px"
            direction="row"
          >
            <Grid item xs={12} md={6}>
              <Controller
                name="fullName"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.fullName ? true : false}
                    helperText={errors.fullName?.message}
                    fullWidth
                    label="Full Name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                    fullWidth
                    label="Email"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {(pocEnabled || pointOfContact || pointOfContactEmail) && (
              <>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="pointOfContact"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Point of Contact"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="pointOfContactEmail"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Email"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: "Required",
                  minLength: {
                    value: 10,
                    message: "Invalid phone number",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.phoneNumber ? true : false}
                    helperText={errors.phoneNumber?.message}
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Monthly Order #</InputLabel>
                <Controller
                  name="monthlyOrders"
                  control={control}
                  render={({ field }) => (
                    <Select
                      value=""
                      label="Monthly Order #"
                      onChange={() => {}}
                      {...field}
                    >
                      <MenuItem value="500_1,000">500 - 1,000</MenuItem>
                      <MenuItem value="1,000_10,000">1,000 - 10,000</MenuItem>
                      <MenuItem value="10,000_50,000">10,000 - 50,000</MenuItem>
                      <MenuItem value="50,000+">50,000+</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Required",
                  minLength: {
                    value: 8,
                    message: "Password must contain 8 characters",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="password"
                    error={errors.password ? true : false}
                    helperText={errors.password?.message}
                    label="Password"
                    variant="outlined"
                  />
                )}
              />
              {!errors.password && (
                <FormHelperText>
                  Password must contain 8 characters
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="passwordConfirmation"
                control={control}
                rules={{
                  required: "Required",
                  validate: (value) =>
                    value === passwordField || "Passwords must match",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.passwordConfirmation ? true : false}
                    helperText={errors.passwordConfirmation?.message}
                    fullWidth
                    type="password"
                    label="Password Confirmation"
                    variant="outlined"
                  />
                )}
              />
              {!errors.passwordConfirmation && (
                <FormHelperText>Passwords must match</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box
            display="flex"
            flexDirection="column"
            width="50%"
            sx={{ margin: { xs: "50px auto", md: "auto auto 0" } }}
          >
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Next
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </GradientBox>
  );
}

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: "#F4F4F9",
      light: "#F4F4F9",
      dark: "#F4F4F9",
      contrastText: "#423D55",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "100px",
          textTransform: "none",
          fontWeight: 400,
          "&.MuiButton-sizeMedium": {
            height: "40px",
            fontSize: "13px",
          },
        },
      },
    },
  },
});
