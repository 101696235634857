import { useState } from "react";
import { Alert, CircularProgress, Link } from "@mui/material";
import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStyles, shipmentStatuses } from "./utils";
import { getOrder } from "../../api/order";
import { MainLayout } from "../../layouts/MainLayout";
import { Card } from "../../shared/Card";
import {
  ERROR,
  track,
  addUserProperties,
  addEventProperties,
  identify,
} from "../../helpers/heap";
import { useTenantTheme } from "../../themes/TenantThemeProvider";
import { Loader } from "../../shared/Loader";

export function Tracking() {
  const { siTheme } = useTenantTheme();
  const styles = useStyles();
  const { shipInsureId } = useParams();
  const [alertMessage, setAlertMessage] = useState("");
  const [shipment, setShipment] = useState({});
  const [alert, setAlert] = useState();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const lookupOrder = async () => {
      setLoading(true);

      try {
        const response = await getOrder(shipInsureId, {
          include_carrier_detail: 1,
        });

        // idenfity Heap user on order lookup
        if (response.data?.shipInsureID) {
          identify(response.data.shipInsureID);
          addUserProperties({
            "Merchant ID": response.data.merchant_id,
            "Merchant Name": response.data.name,
            "Store Name": response.data.store_name,
          });
          addEventProperties({
            "Order Number": response.data.order_number,
          });
        }

        if (response.data?.line_items) {
          setShipment(response.data?.shipment);
          setOrder(response.data);
          setAlertMessage("");
        }
      } catch (error) {
        let message = error.message;

        if (error.response?.status === 404) {
          message = error.response.data.Message;
        }

        track(ERROR, {
          message,
        });

        setAlert("error");
        setAlertMessage(message);
      } finally {
        setLoading(false);
      }
    };

    lookupOrder();
  }, []);

  const getShipmentStatus = () => {
    return shipmentStatuses[order?.shipment?.status] || "";
  };

  const getShipmentStep = (status) => {
    switch (status) {
      case "delivered":
      case "return_to_sender": {
        return 3;
      }
      case "in_transit":
      case "available_for_pickup":
      case "out_for_delivery": {
        return 2;
      }
      case "waiting_for_pickup":
      case "failure":
      case "cancelled":
      case "unknown_carrier":
      case "alert":
      case "error":
      default: {
        return 1;
      }
    }
  };

  const renderFileClaimButton = () => {
    if (order?.fee > 0) {
      return (
        <Box mt="46px">
          <Button href="/claims" variant="contained" fullWidth>
            File a Claim
          </Button>
        </Box>
      );
    }
  };

  const renderOrderInformation = () => {
    if (order === undefined) {
      return;
    }

    const Item = ({ children }) => (
      <Typography
        paragraph
        fontSize="13px"
        lineHeight="20px"
        marginBottom="5px"
      >
        {children}
      </Typography>
    );

    return (
      <Box marginBottom="30px">
        <Item>
          ShipInsureID: <b>{shipInsureId}</b>{" "}
          <Typography
            component="span"
            color={order?.fee > 0 ? "#09B825" : "#FF3E33"}
            fontWeight="bold"
            fontSize="13px"
          >
            {order.fee > 0 ? "Covered" : "Not covered"}
          </Typography>
        </Item>
        <Item>
          Store: <b>{order.store_name}</b>
        </Item>
        <Item>
          Order #: <b>{order.order_number}</b>
        </Item>
        <Item>
          Carrier: <b>{order?.shipment?.carrier || "n/a"}</b>
        </Item>
        <Item>
          Tracking #:{" "}
          <Link
            sx={{ textDecoration: "none" }}
            fontWeight="bold"
            href={order?.shipment?.tracking_url}
            target="_blank"
          >
            {order?.shipment?.tracking_number}
          </Link>
        </Item>
      </Box>
    );
  };

  const renderShipmentSummary = () => {
    if (order === undefined) {
      return;
    }

    return (
      <Box mb="30px">
        <Typography
          paragraph
          fontSize="16px"
          lineHeight="30px"
          fontWeight={700}
        >
          Shipment Status: {getShipmentStatus()}
        </Typography>

        <Stepper
          activeStep={getShipmentStep(order?.shipment?.status)}
          alternativeLabel
          className={styles.statusStepper}
        >
          <Step>
            <StepLabel>Pending</StepLabel>
          </Step>
          <Step>
            <StepLabel>In Transit</StepLabel>
          </Step>
          <Step>
            <StepLabel>Delivered</StepLabel>
          </Step>
        </Stepper>
      </Box>
    );
  };

  const renderShipmentEvents = () => {
    const steps = shipment?.carrier_details?.details;

    if (steps === undefined) {
      return;
    }
    const activeStep = steps.length;

    return (
      <div>
        <Typography
          mb="10px"
          paragraph
          fontSize="16px"
          lineHeight="30px"
          fontWeight={700}
        >
          Shipment History:
        </Typography>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          className={styles.historyStepper}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                {step.message}: {step.city}, {step.state} {step.zipcode}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  };

  const { isThemeLoading } = useTenantTheme();

  // each feature has its own loader that requires a theme (Claims/Tracking);
  // it could be argued that we fetch tracking data the same way we do claims
  // by getting rid of the card loader on this particular page
  if (isThemeLoading) {
    return <Loader />;
  }

  return (
    <MainLayout>
      <Card enableBrand={!siTheme.isPrimaryTheme}>
        {alertMessage && (
          <Box mb="35px">
            <Alert
              onClose={() => setAlertMessage("")}
              variant="filled"
              severity={alert}
              color={alert}
            >
              {alertMessage}
            </Alert>
          </Box>
        )}
        <Typography
          variant="h3"
          fontSize="28px"
          fontWeight={700}
          fontFamily="Montserrat, serif"
          marginBottom="10px"
        >
          Order Tracking
        </Typography>

        {loading && (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py="75px"
          >
            <CircularProgress />
          </Box>
        )}

        {!loading && renderOrderInformation()}
        {!loading && renderShipmentSummary()}
        {!loading && renderShipmentEvents()}
        {!loading && renderFileClaimButton()}
      </Card>
    </MainLayout>
  );
}
