import { CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import { muiTheme } from "../themes/mui-theme";
import { baseTheme } from "../themes/shipinsure";

// forces ShipInsure purple for main loader
// theme because loader will change color
// mid load if theme is fetched then data that
// requires full page loader is fetched; so we
// just default to ShipInsure purple then all
// in app loaders will remain current theme color
const loaderTheme = createTheme({
  ...muiTheme,
  palette: {
    primary: {
      main: baseTheme.colors.primary,
    },
  },
});

export function Loader() {
  return (
    <ThemeProvider theme={loaderTheme}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={80} />
      </div>
    </ThemeProvider>
  );
}
