import { Button, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import { useClaims } from "../ClaimsProvider";

export function Overview() {
  const { order, isSelectAll, onSelectAllToggle } = useClaims();

  return (
    <>
      <Typography variant="h3">Resolution Center</Typography>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Typography paragraph mb="5px">
            Order#:{" "}
            <Typography display="inline" fontWeight={700} component="span">
              {order.order_number}
            </Typography>
          </Typography>
          {order.created_at && (
            <Typography paragraph mb="5px">
              Order placed:{" "}
              <Typography display="inline" fontWeight={600} component="span">
                {format(new Date(order.created_at), "MMMM d, yyyy")}
              </Typography>
            </Typography>
          )}
          <Typography paragraph mb="5px">
            Store:{" "}
            <Typography display="inline" fontWeight={600} component="span">
              {order.store_name}
            </Typography>
          </Typography>
          <Typography paragraph mb="5px">
            Items:{" "}
            <Typography display="inline" fontWeight={600} component="span">
              {order.line_items.length}
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent={{ xs: "center", md: "right" }}
        >
          <Button
            variant={isSelectAll ? "contained" : "outlined"}
            sx={{ fontSize: "12px", mt: { xs: "10px", md: "0px" } }}
            onClick={onSelectAllToggle}
          >
            My entire order was lost, damaged, or stolen
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
