import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Snackbar,
} from "@mui/material";
import { Header } from "../../shared/Header";
import {
  createMerchant,
  getMerchant,
  updateMerchant,
} from "../../api/merchants";
import { identify, addUserProperties } from "../../helpers/heap";

const OnboardingContext = createContext(null);
export const steps = {
  STEP_1: "/onboarding/account",
  STEP_2: "/onboarding/information",
  STEP_3: "/onboarding/coverage",
  STEP_4: "/onboarding/billing",
};

export function OnboardingProvider({ children }) {
  const [merchant, setMerchant] = useState();
  const [isLoading, setIsLoading] = useState();
  const [params] = useSearchParams();
  const [formError, setFormError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // we are using 1 form for all steps
  // to build data as we go and submit at any time
  const form = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      storeUrl: "",
      storeName: "",
      phoneNumber: "",
      pointOfContact: "",
      pointOfContactEmail: "",
      monthlyOrders: "",
      coverage: "",
      password: "",
      passwordConfirmation: "",
      platform: "Shopify", // default
    },
  });

  // we pre-fetch data if they come from shopify
  // to prefill the form
  useEffect(() => {
    const shopifyId = params.get("shopify_id");

    const fetchMerchant = async (identifier) => {
      try {
        const response = await getMerchant(identifier);

        if (response.data?.id) {
          setIsLoading(false);
          setMerchant(response.data);
        }
      } catch (error) {
        setIsLoading(false);
        setFormError("There was a problem getting merchant data");
      }
    };

    if (shopifyId) {
      setIsLoading(true);
      fetchMerchant(shopifyId);
    }
  }, [params]);

  const navigateFlow = () => {
    const route = location.pathname;

    switch (route) {
      case steps.STEP_1: {
        navigate(steps.STEP_2);
        break;
      }
      case steps.STEP_2: {
        navigate(steps.STEP_3);
        break;
      }
      case steps.STEP_3: {
        navigate(steps.STEP_4);
        break;
      }
      default: {
        navigate(route);
      }
    }
  };

  const onFormSubmit = async (values) => {
    const shopifyId = params.get("shopify_id");
    const buildPayload = (data) => {
      const result = {};

      for (const key in data) {
        const value = data[key];

        if (value !== null && value !== undefined && value !== "") {
          result[key] = value;
        }
      }

      return result;
    };

    // remove any null/empty values to
    // build PUT payload for each step
    const payload = buildPayload({
      full_name: values.fullName,
      name: values.storeName,
      email_address: values.email,
      domain_name: values.storeUrl,
      phone: values.phoneNumber,
      platform: values.platform,
      monthly_orders: values.monthlyOrders,
      cs_name: values.pointOfContact,
      cs_email_address: values.pointOfContactEmail,
      has_full_coverage: values.coverage === 'full_coverage',
      opt_in_out: values.coverage === 'out' ? 'out' : 'in',
      password: values.password,
      shopify_id: shopifyId ?? null,
    });

    // we do initial POST on information step and set merchantUuid
    // for subsequent update calls; if there is a Shopify ID, we want to update the record
    if (
      (location.pathname === steps.STEP_1 && !shopifyId) ||
      (location.pathname === steps.STEP_1 && !merchant?.merchant_id)
    ) {
      try {
        const response = await createMerchant(payload);

        // identify with merchant id to track
        // to track what merchants onboard through Heap
        if (response.data.merchant_id) {
          identify(response.data.merchant_id);
        }

        setMerchant(response.data);
        navigateFlow();
      } catch (error) {
        setFormError(error.message);
      }
    } else {
      // PUT with merchantUuid to update resource on other steps
      try {
        const response = await updateMerchant(merchant.merchant_id, payload);

        // identify with merchant id to track
        // to track what merchants onboard through Heap
        if (response.data.merchant_id) {
          identify(response.data.merchant_id);
          addUserProperties({
            Platform: response.data.platform,
            "Domain Name": response.data.domain_name,
            "Store Name": response.data.name,
          });
        }

        setMerchant(response.data);

        navigateFlow();
      } catch (error) {
        setFormError(error.message);
      }
    }
  };

  return (
    <OnboardingContext.Provider value={{ merchant, form }}>
      <Container sx={{ paddingBottom: "108px" }}>
        <Header />
        {isLoading ? (
          <Box display="flex" justifyContent="center" py="120px">
            <CircularProgress size={80} />
          </Box>
        ) : (
          <form onSubmit={form.handleSubmit(onFormSubmit)}>{children}</form>
        )}
      </Container>
      <Snackbar
        open={!!formError}
        autoHideDuration={3000}
        onClose={(_, reason) => {
          if (reason === "clickaway") return;
          setFormError(false);
        }}
      >
        <Alert
          onClose={() => setFormError(false)}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {formError}
        </Alert>
      </Snackbar>
    </OnboardingContext.Provider>
  );
}

export const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  return context;
};
