export function HappyBox() {
  return (
    <svg
      width="48"
      height="52"
      viewBox="0 0 48 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0023 26.2328L2.23486 14.5118V36.8374L24.0023 49.1165V26.2328Z"
        fill="#E0E3FF"
      />
      <path
        d="M46.08 11.4948L25.7723 0.454155C25.2297 0.156259 24.6199 0 24 0C23.3801 0 22.7703 0.156259 22.2277 0.454155L1.92 11.4993C1.34005 11.8146 0.855931 12.2789 0.518196 12.8435C0.180462 13.4082 0.00150059 14.0526 0 14.7095V36.6394C0.00150059 37.2962 0.180462 37.9406 0.518196 38.5053C0.855931 39.07 1.34005 39.5342 1.92 39.8495L22.2277 50.8947C22.7703 51.1926 23.3801 51.3488 24 51.3488C24.6199 51.3488 25.2297 51.1926 25.7723 50.8947L46.08 39.8495C46.6599 39.5342 47.1441 39.07 47.4818 38.5053C47.8195 37.9406 47.9985 37.2962 48 36.6394V14.7118C47.9997 14.0537 47.8214 13.4079 47.4836 12.8419C47.1458 12.2759 46.661 11.8106 46.08 11.4948ZM24 3.6643L42.54 13.7533L24 23.8423L5.46 13.7533L24 3.6643ZM3.69231 16.9635L22.1538 27.002V46.6733L3.69231 36.6416V16.9635ZM25.8462 46.6733V27.0112L44.3077 16.9635V36.6325L25.8462 46.6733Z"
        fill="#6675FF"
      />
      <path
        d="M34.611 29.5812C34.611 30.5059 33.8614 31.2556 32.9366 31.2556C32.0119 31.2556 31.2622 30.5059 31.2622 29.5812C31.2622 28.6564 32.0119 27.9067 32.9366 27.9067C33.8614 27.9067 34.611 28.6564 34.611 29.5812Z"
        fill="#6675FF"
      />
      <path
        d="M40.1843 27.3487C40.1843 28.2735 39.4346 29.0232 38.5099 29.0232C37.5851 29.0232 36.8354 28.2735 36.8354 27.3487C36.8354 26.424 37.5851 25.6743 38.5099 25.6743C39.4346 25.6743 40.1843 26.424 40.1843 27.3487Z"
        fill="#6675FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.3505 30.165C42.0732 30.308 42.542 31.0033 42.3974 31.7181C41.9807 33.7789 40.6429 35.5423 38.7589 36.5144L37.8029 37.0077C35.7061 38.0896 33.2461 38.2506 31.0234 37.4512C30.3307 37.2021 29.9734 36.4448 30.2252 35.7598C30.4771 35.0747 31.2429 34.7213 31.9356 34.9704C33.4544 35.5166 35.1354 35.4066 36.5682 34.6673L37.5242 34.1741C38.6923 33.5714 39.5216 32.4781 39.78 31.2004C39.9246 30.4856 40.6277 30.0221 41.3505 30.165Z"
        fill="#6675FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.505 6.37819C35.9442 7.14169 35.6866 8.11975 34.9298 8.56276L14.3299 20.6207V27.4254C14.3299 28.3081 13.6205 29.0236 12.7455 29.0236C11.8705 29.0236 11.1611 28.3081 11.1611 27.4254V18.7798L33.3395 5.79792C34.0963 5.3549 35.0659 5.6147 35.505 6.37819Z"
        fill="#6675FF"
      />
    </svg>
  );
}
