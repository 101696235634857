const HEAP_ID = import.meta.env.VITE_HEAP_ID;

export function addEventProperties(data) {
  window?.heap?.addEventProperties(data);
}

export function addUserProperties(data) {
  window?.heap?.addUserProperties(data);
}

export function identify(id) {
  window?.heap?.identify(id);
}

export function loadHeap() {
  const script = document.createElement("script");
  script.innerHTML = `
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    heap.load("${HEAP_ID}");
  `;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
}

export function track(eventName, properties) {
  window?.heap?.track(eventName, properties);
}
export const VIEW_SCREEN = "VIEW_SCREEN";
export const ERROR = "ERROR";
